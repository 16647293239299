import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { db } from './firebaseConfig';
import { AuthContext } from './AuthContext';
import Sidebar from './Sidebar';
import { collection, addDoc, query, where, onSnapshot, orderBy, serverTimestamp, doc, updateDoc, getDoc, increment } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import checkForActiveSubscription from './utilities/SubscriptionChecker';
import MessageList from './components/MessageList';
import ChatInput from './components/ChatInput';
import DropdownMenu from './components/DropdownMenu';
import AlertMessage from './components/AlertMessage';
import Overlay from './components/Overlay';
import { setDoc } from 'firebase/firestore';
import './styles/Chat.css'; 

const Chat = () => {
  const { currentUser, loading } = useContext(AuthContext);
  const [selectedThread, setSelectedThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [freeMessages, setFreeMessages] = useState(0);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Check if a query exists in localStorage
    const savedQuery = localStorage.getItem('userQuery');
    if (savedQuery) {
      setNewMessage(savedQuery); // Set the message state to the saved query
      localStorage.removeItem('userQuery'); // Clear the saved query from localStorage
      // Optionally, send the message automatically
      handleSendMessage();
    }
  }, []);

  // Function to toggle the dropdown menu
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  
  useEffect(() => {
    // Add when the dropdown is opened
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Step 4: Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  // Initialize and fetch freeMessages, now returns a promise
  const initializeAndFetchFreeMessages = async () => {
    const quotaRef = doc(db, 'quotas', currentUser.uid);
    let quotaSnap = await getDoc(quotaRef);

    if (!quotaSnap.exists()) {
      await setDoc(quotaRef, { 
        uid: currentUser.uid,
        freeMessages: 5, 
        quotaCreated: serverTimestamp(), 
        quotaUpdated: serverTimestamp()
      });
      quotaSnap = await getDoc(quotaRef); // Refetch after setting
    } else if (quotaSnap.data().freeMessages === undefined) {
      await updateDoc(quotaRef, { 
        freeMessages: 5,
        quotaUpdated: serverTimestamp()
      });
      quotaSnap = await getDoc(quotaRef); // Refetch after updating
    }

    setFreeMessages(quotaSnap.data().freeMessages || 0);
  };

  // UseEffect hook for initializing free messages and checking subscription
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        // Wait for the initialization of free messages
        await initializeAndFetchFreeMessages();
        // After initialization is complete, check the subscription status
        await checkSubscription();
      }
    };
    fetchData();
  }, [currentUser]);

  const checkSubscription = async () => {
    try {
      console.log('Checking subscription for user:', currentUser.uid); // Logging user ID
      const subscriptions = await checkForActiveSubscription(currentUser.uid);
      console.log('Subscriptions received:', subscriptions); // Logging received subscriptions
      const currentDate = new Date();
      const hasActive = subscriptions.some(sub => 
        currentDate >= new Date(sub.startDate) && currentDate <= new Date(sub.endDate)
      );

      const hasCancelled = subscriptions.some(sub => sub.status === 'cancelled');

      console.log('Has active subscription:', hasActive); // Logging subscription status
      console.log('Has cancelled subscription:', hasCancelled); // Logging if any subscription is cancelled

      setHasActiveSubscription(hasActive);

      const quotaRef = doc(db, 'quotas', currentUser.uid);
      const quotaDoc = await getDoc(quotaRef);
      
      let freeMessagesAvailable = quotaDoc.exists() ? quotaDoc.data().freeMessages : 0;
      console.log('Free messages available:', freeMessagesAvailable);

      if (!quotaDoc.exists() && hasCancelled) {
        // If the subscription is cancelled and there's no quota doc, create one with 5 free messages
        await setDoc(quotaRef, {
          freeMessages: 5,
          quotaCreated: serverTimestamp(),
          quotaUpdated: serverTimestamp(),
        });
        freeMessagesAvailable = 5;
      }

      // Update free messages only if no active subscription
      if (!hasActive) {
        setFreeMessages(freeMessagesAvailable);
      }

      if (!hasActive && freeMessagesAvailable <= 0) {
        setAlertMessage('You do not have an active subscription. Please subscribe to continue.');
        setShowOverlay(true);
        const timer = setTimeout(() => {
          navigate('/Subscriptions');
        }, 1000000000);
        return () => clearTimeout(timer);
      } else {
        setShowOverlay(false);
      }
    } catch (error) {
      console.error('Error checking for active subscriptions:', error);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
    setShowOverlay(false); // Hide overlay
    navigate('/Subscriptions');
  };

  // Handle thread selection and fetching messages
  useEffect(() => {
    if (currentUser) {
      const threadsRef = collection(db, 'threads');
      const q = query(threadsRef, where('user_id', '==', currentUser.uid), orderBy('last_updated', 'desc'));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size === 0) {
          createAndSelectNewThread(); // Create new thread if user has none
        } else if (!selectedThread || !querySnapshot.docs.some(doc => doc.id === selectedThread.id)) {
          const mostRecentThread = querySnapshot.docs[0].data();
          mostRecentThread.id = querySnapshot.docs[0].id;
          setSelectedThread(mostRecentThread); // Select the most recent thread
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser, selectedThread]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  // Function to create a new thread with a welcome message
  const createAndSelectNewThread = async () => {
    const newThreadRef = await addDoc(collection(db, 'threads'), {
      user_id: currentUser.uid,
      last_updated: serverTimestamp(),
    });
    setSelectedThread({ id: newThreadRef.id, user_id: currentUser.uid, last_updated: new Date() });
    addWelcomeMessageToThread(newThreadRef.id);
  };

  // Add a welcome message to a thread
  const addWelcomeMessageToThread = async (threadId) => {
    await addDoc(collection(db, 'threads', threadId, 'messages'), {
      text: "Welcome to TaxBot! How can we assist you today?",
      sender: "system",
      timestamp: serverTimestamp(),
    });
  };

  // Fetch messages for the selected thread
  useEffect(() => {
    if (selectedThread?.id) {
      const messagesRef = collection(db, 'threads', selectedThread.id, 'messages');
      const unsubscribe = onSnapshot(query(messagesRef, orderBy('timestamp', 'asc')), (querySnapshot) => {
        setMessages(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      });
      return () => unsubscribe();
    } else {
      setMessages([]);
    }
  }, [selectedThread]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && !isSending) {
      setIsSending(true);
  
      const userMessage = {
        text: newMessage,
        sender: 'user',
        timestamp: new Date(),
      };
  
      // Add the new message to the messages state
      setMessages([...messages, userMessage]);
      setNewMessage('');
  
      // Check for the first user message
      const isFirstMessage = messages.length === 0 || messages.every(msg => msg.sender !== 'user');
  
      if (!hasActiveSubscription) {
        const quotaRef = doc(db, 'quotas', currentUser.uid);
        const quotaSnap = await getDoc(quotaRef);
  
        if (quotaSnap.exists() && quotaSnap.data().freeMessages <= 0) {
          setAlertMessage('You have reached your limit of free messages. Please subscribe to continue.');
          setShowOverlay(true);
          setIsSending(false);
          return;
        }
  
        if (quotaSnap.exists()) {
          await updateDoc(quotaRef, {
            freeMessages: increment(-1),
            quotaUpdated: serverTimestamp()
          });
  
          const updatedQuotaSnap = await getDoc(quotaRef);
          setFreeMessages(updatedQuotaSnap.data().freeMessages);
        }
      }
  
      // Add the user's message to Firestore
      await addDoc(collection(db, 'threads', selectedThread.id, 'messages'), userMessage);
  
      // Update the thread's `last_updated` timestamp
      const threadRef = doc(db, 'threads', selectedThread.id);
      await updateDoc(threadRef, {
        last_updated: serverTimestamp(),
      });
  
      if (isFirstMessage) {
        try {
          const titleResponse = await fetch('https://title-generator-iwqskruira-uc.a.run.app/summarize', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: newMessage })
          });
  
          if (!titleResponse.ok) {
            throw new Error(`HTTP error! status: ${titleResponse.status}`);
          }
  
          const titleData = await titleResponse.json();
          let title = titleData.choices[0].message.content;
          title = title.replace(/^"|"$/g, ''); // Strip quotes from the title
  
          // Update the thread title in Firestore
          await updateDoc(threadRef, {
            title: title,
            last_updated: serverTimestamp(),
          });
        } catch (error) {
          console.error('Title generation request failed:', error);
        }
      }
  
      // Fetch response from the API
      try {
        const previousMessages = messages.map(msg => msg.text);
        const response = await fetch('https://taxbot-api-iwqskruira-uc.a.run.app/ask', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            question: newMessage,  // Send only the new message as the question
            conversation_history: previousMessages  // Send the array of previous user messages
          })
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const responseData = await response.json();
  
        // Add the system's response to Firestore
        await addDoc(collection(db, 'threads', selectedThread.id, 'messages'), {
          text: responseData.response,
          sender: 'system',
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        console.error('Request failed:', error);
        setAlertMessage('Error: Could not send the message. Please try again.');
        setShowOverlay(true);
      } finally {
        setIsSending(false); // Stop sending process
      }
    }
  };
  

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Loading and user check
  if (loading) return <div>Loading...</div>;
  if (!currentUser) return null;

  return (
    <div className="app-container">
      {showOverlay && <Overlay />}
      {alertMessage && <AlertMessage message={alertMessage} onClose={handleCloseAlert} />}
      <Sidebar onSelectThread={setSelectedThread} selectedThreadId={selectedThread?.id} onCreateNewThread={createAndSelectNewThread} />
      <div className="chat-container">
        <div className="chat-header">
          <h2 className="text-2xl font-bold text-black !important">TaxBot</h2>
          {!hasActiveSubscription && <div className="free-messages-info">Free messages left: {freeMessages}</div>}
          <DropdownMenu showDropdown={showDropdown} toggleDropdown={setShowDropdown} navigate={navigate} handleLogout={handleLogout} />
        </div>
        <MessageList messages={messages} isSending={isSending} threadId={selectedThread?.id} userId={currentUser.uid} />
        <ChatInput onSendMessage={handleSendMessage} newMessage={newMessage} setNewMessage={setNewMessage} isSending={isSending} />
      </div>
    </div>
  );
};

export default Chat;